/*
 * Link
 *
 * Replacement component to handle internal and external links. Use this instead of an anchor tag.
 * It will provide MUI Link functionality as well as differentiate between internal and external links - adding security tags if external.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';

//////////////////////////

// REVIEW: Do we need to pass through an onClick as well? What's the use case? - It's probably fine coming through with {...rest}
// TODO: Test that premise.

//////////////////////////

const propTypes = {
  to: PropTypes.string,
  target: PropTypes.string,
  rel: PropTypes.string,
  classes: PropTypes.object,
  className: PropTypes.string,  // While className will work, it will not be as specific as properties defined in the theme // prefer classes object
  children: PropTypes.any.isRequired,
}

const defaultProps = {
  keepScrollPosition: false,
}

export const Link = props => {

  let isInternal = false;
  let {
    to,
    target,
    rel,
    classes,
    className,
    children,
    keepScrollPosition,
    ...rest
  } = props;
  isInternal = isUrlInternal(to);
  target = target || ( isInternal ? undefined : "_blank" );
  rel = applySecureLinkingAttributes(target, rel);


  //////

  return (

    isInternal ? (
      // Gatsby's link component must be used in order to preload items on rollover
      <GatsbyLink
        to = {to}
        target = {target}
        rel = {rel}
        classes = {classes}
        className = {clsx(className)}
        state = {{ keepScrollPosition }}
        {...rest}
        >
        {children}
      </GatsbyLink>

    ) : (
      // Not using GatsbyLink because external sites shouldn't be preloaded
      // Not using MaterialUiLink because consistent styling is already manually set up in this file
      
      <a
        href = {to}
        target = {target}
        rel = {rel}
        className = {clsx(
          className,
          classes && classes.root
        )}
        {...rest}
        >
        {children}
      </a>

    )

  )

};

Link.propTypes = propTypes;
Link.defaultProps = defaultProps;
export default Link;

///////////////////////////

const isUrlInternal = (url) => {
  let isInternal = true;
  if( url.indexOf("http") === 0 || 
      url.indexOf("//") === 0
      ) {
    isInternal = false;
  }
  return isInternal;
}

const applySecureLinkingAttributes = (target, rel) => {
  if( target === "_blank" ) {
    if(rel) {
      if(!rel.includes("noreferrer"))    rel += " noreferrer";
      if(!rel.includes("noopener"))      rel += " noopener";
    } else {
      rel = "noreferrer noopener";
    }
  }
  return rel;
}