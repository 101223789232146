/**
 * GLOBAL METADATA
 Define brand and job specific global constants here.
 */



// Arbitrary site meta for reference elsewhere
export const METADATA = {

  siteTitle: 'Project Template', // Site Title
  titleBarPostfix: ' | Project Template',
  description: 'This is the global description of the webpage',

}



export const COLORS = {

  // Arbitrary definitions
  brandYellow: '#FECC07',
  brandBlack: '#000000',
  brandWhite: '#FFFFFF',
  brandGrey: '#595959',

};



export const PATHS = {

  root: '/',
  componentDemos: '/demo/component-demos',
  layoutComponents: '/demo/layout-components',
  animation: '/demo/animation',
  pageTransitions1: '/demo/page-transitions/page-transitions-1',
  pageTransitions2: '/demo/page-transitions/page-transitions-2',
  buildTimeDataFetching: '/demo/build-time-data-fetching',
  clientSideDataFetching: '/demo/client-side-data-fetching',
  clientSideRouting: '/demo/client-side-routing',
  offlineCaching: '/demo/offline-caching',
  theming: '/demo/theming',
  resources: '/demo/resources',
  notFound: '/404',

};



