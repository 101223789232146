/**
 * Place Material UI Theme Overrides in here.
 * These override the default props and instance changes of elements.
 * @see {@link https://material-ui.com/customization/globals/}
 */

import { sharedOverrides } from "../shared-overrides";

////////

export const OVERRIDES = {

  ...sharedOverrides,

  MuiButton: {
    root: {
      margin: '1em 1em 1em 0',
      '&:first-child': {
        marginLeft: "0"
      },
    }
  },

};
