import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from '/src/components/Link';
import { PATHS } from '/src/globals';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { COLORS } from '../../globals';

//////////////////////////

const useStyles = makeStyles( theme => ({

  menuContainer: {
    maxWidth: '1024px',
    margin: '0 auto',
  },

  headerMenuContainer: {},

  footerMenuContainer: {
    marginTop: '8em',
  },

  navList: {
    listStyle: 'none',
    padding: 0,
    '& li': {
      display: 'inline-block',
      padding: '0.5em 2em 0.5em 0',
    }
  },

  headerLink: {
    color: `${COLORS.brandWhite} !important`,
    textDecoration: 'none',
    '&:visited': {
      color: `${theme.palette.tertiary.contrastText} !important`,
    },
    '&:hover': {
      color: `${theme.palette.secondary.main} !important`,
      textDecoration: 'underline',
    },
    '&:active': {
      color: `${COLORS.brandWhite} !important`,
      opacity: '0.7',
    },
  },

  bodyLink: {
    color: `${theme.palette.secondary.main} !important`,
    textDecoration: 'underline',
    '&:hover': {
      color: `${theme.palette.primary.main} !important`,
      textDecoration: 'none',
    },
    '&:active': {
      color: `${theme.palette.secondary.main} !important`,
      opacity: '0.7',
    },
    '&:visited': {
      color: `${theme.palette.tertiary.main} !important`,
    }
  },

}));

//////////////////////////

const propTypes = {
  variant: PropTypes.string,
}

const defaultProps = {
  variant: 'body',
}

export const DemoNavigation = props => {

  const classes = useStyles(props);
  const {
    variant,
  } = props;

  const linkClass = variant === 'header' ? classes.headerLink : classes.bodyLink; 

  //////

  return (

    <nav className={clsx(
        classes.menuContainer,
        variant === 'body' && classes.footerMenuContainer,
      )}>
      <Typography component="div" variant="body1">
        <ul className={classes.navList}>
          <li><Link to={PATHS.componentDemos} className={linkClass}>Components</Link></li>
          <li><Link to={PATHS.layoutComponents} className={linkClass}>Layout Components</Link></li>
          <li><Link to={PATHS.animation} className={linkClass}>Animation</Link></li>
          <li><Link to={PATHS.pageTransitions1} className={linkClass}>Page Transitions</Link></li>
          {/*FIXME: This page breaks the site.The 3rd party API limits the number of requests we can make so the Graphql gets error.*/}
          {/*TODO: Investigate the external API and add this page back. */}
          {/*<li><Link to={PATHS.buildTimeDataFetching} className={linkClass}>Build-Time Data Fetching</Link></li>*/}
          <li><Link to={PATHS.clientSideDataFetching} className={linkClass}>Client-Side Data Fetching</Link></li>
          <li><Link to={PATHS.clientSideRouting} className={linkClass}>Client-Side Routing</Link></li>
          <li><Link to={PATHS.offlineCaching} className={linkClass}>Offline Caching</Link></li>
          <li><Link to={PATHS.theming} className={linkClass}>Theming</Link></li>
          <li><Link to={PATHS.resources} className={linkClass}>Resources</Link></li>
          <li><Link to={PATHS.notFound} className={linkClass}>Not Found</Link></li>
        </ul>
      </Typography>
    </nav>

  );
};

DemoNavigation.propTypes = propTypes;
DemoNavigation.defaultProps = defaultProps;
export default DemoNavigation;
