/**
 * Define a theme in here.
 * - MUI color palette
 * - MUI typography
 * - and standard style classes
 */

import { createTheme, makeStyles, responsiveFontSizes } from '@material-ui/core/styles';

import { COLORS } from '/src/globals';
import { DEFAULT_PROPS } from './default-props';
import { OVERRIDES } from './overrides';

/////////////////////////

const palette = {

  // Standard Material UI colors
  //////////////////////////////
  // Each MUI component may only support a subset of these colour objects.
  // These palette definitions can also be used throughout this file.

  primary: {
    main: COLORS.brandYellow,
    contrastText: COLORS.brandBlack,
  },
  secondary: {
    main: COLORS.brandBlack,
    contrastText: COLORS.brandWhite,
  },
  error: {
    main: '#f44336',
  },
  warning: {
    main: '#ff9800',
  },
  info: {
    main: '#2196f3',
  },
  success: {
    main: '#4caf50',
  },

  // Non-Standard Material UI colours
  ///////////////////////////////////
  // These are not supported by MUI components but can used through directly access of the theme object

  tertiary: {
    main: COLORS.brandGrey,
    contrastText: COLORS.brandWhite,
  },
  header: {
    main: COLORS.brandGrey,
    contrastText: COLORS.brandWhite,
  },
  body: {
    main: COLORS.brandWhite,
    contrastText: COLORS.brandBlack,
  },

}


const typography = {

  // Typography contains the variants that you can define for use in material UI themes.
  // You cannot add to these variants as they are defined by MUI

  // NOTE: If fontFamily isn't defined, Roboto is loaded and used by default
  // Ref: https://www.gatsbyjs.com/plugins/gatsby-theme-material-ui/#webfonts
  // fontFamily: [
  //   'Pattaya',
  //   'sans-serif'
  // ].join(','),

  // NOTE: These standard typography styles defined in this theme cannot be overwritten by classes which define the properties without using '!important'

  h1: {
    fontWeight: 700,
    color: palette.primary.main,
    margin: '1em 0 0.5em',
  },
  h2: {
    fontWeight: 700,
    lineHeight: 1,
    color: palette.primary.contrastText,
    margin: '1em 0 0.5em',
  },
  h3: {
    fontWeight: 700,
    fontStyle: 'italic',
    color: palette.primary.main,
    margin: '1em 0 0.5em',
  },
  h4: {
    fontWeight: 500,
    fontStyle: 'normal',
    color: palette.primary.main,
    margin: '1em 0 0.5em',
    '& a': {
      color: palette.secondary.main,
      '&:hover, &:active': {
        color: palette.tertiary.main,
      },
      '&:visited': {
        color: palette.primary.main,
      }
    }
  },
  h5: {
    fontWeight: 700,
    fontStyle: 'normal',
    color: palette.primary.main,
    margin: '1em 0 0.5em',
  },
  h6: {
    fontWeight: 700,
    fontStyle: 'normal',
    color: palette.primary.main,
    margin: '1em 0 0.5em',
  },
  subtitle1: {
    fontWeight: 600,
    fontStyle: 'normal',
    margin: '0.8em 0 0.3em',
    color: palette.secondary.main,
  },
  subtitle2: {
    fontWeight: 400,
    fontStyle: 'normal',
    margin: '0.8em 0 0.3em',
    color: palette.secondary.main,
  },
  body1: {
    fontWeight: 400,
    fontStyle: 'normal',
    margin: '1em 0 0.5em',
    color: palette.secondary.main,
    '& a': {
      color: palette.tertiary.main,
      '&:hover, &:active': {
        color: palette.primary.main,
      },
      '&:visited': {
        color: palette.secondary.main,
      }
    }
  },
  body2: {
    fontWeight: 400,
    fontStyle: 'normal',
    margin: '1em 0 0.5em',
    color: palette.secondary.main,
  },
  caption: {
    fontWeight: 400,
    fontStyle: 'italic',
    color: palette.secondary.main,
  },
  // overline: {
  // },

}


// These styles are not part of material UI schema
// The function is defined here so that any styles that are radically different for each theme can be defined within it.
// ie. Styles which differ across themes according to position, margin, images, etc.
// For global styles that are identical across themes or only require property value changes, use 'useSharedStyles in shared-styles.js instead.
// NOTE: !importants will be needed here if overiding aspects laready defined int he theme.
// This is because this function is defined before the ThemeProvider is assigned the theme, and thus the theme takes precedence.
// THere seems to be no way around this if it is to be passed down inside the theme object.

const useStyles = makeStyles( theme => ({

  // Use props passed in like this.
  // className: props => ({
  //   //
  // }),

  // rounded square shape
  paperShape: {
    borderRadius: '10px !important',
  },

}));


const theme = createTheme({
  palette,
  typography,
  props: DEFAULT_PROPS,
  overrides: OVERRIDES,
});

// add on non-MUI elements
theme.useStyles = useStyles;

export default responsiveFontSizes(theme);
