/**
 * Place Material UI Theme Overrides in here that should be included in every theme.
 * These override the default props and instance changes of elements.
 * @see {@link https://material-ui.com/customization/globals/}
 */

/////////////

export const sharedOverrides = {

  MuiCssBaseline: {

    '@global': {
      body: {
        margin: 0,
      },
    },
    
  },
  
}