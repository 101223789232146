import React from 'react';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core';

//////////////////////////

const Route2Heading = props => {

  const theme = useTheme();

  return ( <>
    <Typography
      variant = "h1"
      style = {{
        color: theme.palette.primary.contrastText,
      }}
      >
      Route 2
    </Typography>
  </> );
  
};

export default Route2Heading;
