// import React from 'react';
// import { ThemeProvider } from '@material-ui/core/styles';
// import customTheme from '/src/themes/default';
// import CssBaseline from '@material-ui/core/CssBaseline';
// import DefaultLayout from '/src/layouts';

///////////////////////

export const shouldUpdateScroll = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}) => {

  // use standard Gatsby scroll behaviour by default
  let scrollWithGatsby = true;

  if(prevRouterProps && routerProps.location.state.keepScrollPosition) {
    const scrollPositionBeforeRoute = getSavedScrollPosition(prevRouterProps.location);
    // Turn off default behaviour and manage scrolling manually
    scrollWithGatsby = false;
    window.scrollTo( ...scrollPositionBeforeRoute );
  }
  
  return scrollWithGatsby;
}



/* 
 NOTE: Implementing the ThemeProvider through here has been abandoned for now.

 ## Why do it at all?
- Allow providing the theme for all pages without reliance on a Layout component
- Allow using a layout component explicitly rather than hidden through a plugin
What Was the problem?
- The theme wasn't properly getting passed to the static pages.

 ## Related references:
- https://galenwong.github.io/blog/2020-02-27-mui-jss-rendering/

 ## How does it work at the moment?
- gatsby-theme-material-ui allows using the ThemeProvider (We've used it in the layout component)
- gatsby-theme-material-ui allows loading in fonts
- gatsby-plugin-layout automatically grabs the index.js int he layout directory and applies it as a layout
- There is potentially some FUOC (seen through build and serve)
*/



/***
 * Use this function to wrap the static pages in any elements that won't get unmounted on page changes.
  */

// export const wrapPageElement = ({ element, props }) => {
//   return ( <>
//     <ThemeProvider theme={customTheme}>
//       {/* <CssBaseline /> */}
//       <DefaultLayout>
//         {element}
//       </DefaultLayout>
//     </ThemeProvider>
//    </> )
// }



/***
 * Use this function to include any providers needed on all pages.
 * (SSR version of wrapPageElement).
 * TODO: Include redux providers here if necesssary.
 */

// export const wrapRootElement = ({ element }) => {
//   return ( <>
//         {element}
//   </> );
// }