import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import defaultTheme from '/src/styles/theme-default';
import alternateTheme from '/src/styles/theme-alternate';
import CssBaseline from '@material-ui/core/CssBaseline';
import { gsap } from 'gsap';
import { DemoNavigation } from '/src/collections/DemoNavigation';
import MainHeading from '/src/page-sections/demo/layout/MainHeading';
import Route1Heading from '/src/page-sections/demo/layout/Route1Heading';
import Route2Heading from '/src/page-sections/demo/layout/Route2Heading';
import { Router } from '@reach/router';

//////////////////////////

const useStyles = makeStyles( theme => ({

  layoutContainer: {
    display: 'flex',
    flexFlow: 'column',
    minHeight: '100vh',
  },

  layoutHeader: (props) => ({
    padding: '20px',
    textAlign: 'center',
    overflow: 'hidden',
  }),

  layoutContent: (props) => ({
    flexGrow: 1,
  }),

}));

// Create context and it's default data directly in the component that owns it.
// Export it so that child components can import it for communication between the components
// Passing functions back and forth can help this.
const contextData = {
  message: "Default message",
  headerLarge: false,
}
export const LayoutContext = React.createContext(contextData);

//////////////////////////

const DefaultLayout = (props) => {

  const [theme, setTheme] = useState(defaultTheme);
  const classes = useStyles(props);
  const [ message, setMessage ] = useState("Hello World!");
  const {
    children
  } = props;
  
  /////////

  const enlargeHeader = (delay=0) => {
    setMessage("Header is large");
    gsap.to("#site-title", { delay, duration: 2, scale: 1.3, ease: 'elastic' });
  }
  const shrinkHeader = (delay=0) => {
    setMessage("Header is small");
    gsap.to("#site-title", { delay, duration: 2, scale: 1, ease: 'elastic' });
  }
  const useDefaultTheme = () => {
    setTheme(defaultTheme);
  }
  const useAlternateTheme = () => {
    setTheme(alternateTheme);
  }

  /////////
  
  // Run text animation only on first render
  useEffect(() => {
    enlargeHeader(1);
  }, [])

  //////

  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LayoutContext.Provider
        value = {{
          message,
          shrinkHeader,
          enlargeHeader,
          useDefaultTheme,
          useAlternateTheme,
        }}
        >

        <div className={clsx(classes.layoutContainer)}>

          <header
            className = {clsx(classes.layoutHeader)}
            // Colors are applied via style in this component because ThemeProvider wasonly just instigated
            // So this components useStyles doesn't have access to it.
            style = {{
              backgroundColor: theme.palette.header.main,
              color: theme.palette.header.contrastText,
            }}
            >
            <div id="site-title">
              {/* NOTE: Routing is used here to provide an example of using routes in the layout component. They are not required. */}
              <Router basepath="/demo/client-side-routing.html">
                <Route1Heading path="/route-1" />
                <Route2Heading path="/route-2" />
                <MainHeading default/>
              </Router>
            </div>
            <DemoNavigation variant="header"/>
          </header>

          <div
            className = {clsx(classes.layoutContent)}
            // Colors are applied via style in this component because ThemeProvider was only just instigated
            // So this useStyles function that built the classes object above didn't have access to it.
            style = {{
              backgroundColor: theme.palette.body.main,
            }}
            >
            {children}
          </div>

        </div>

      </LayoutContext.Provider>
    </ThemeProvider>

  )
}

export default DefaultLayout;